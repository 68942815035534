import React from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import {
  createRules,
  FormFields,
  useAsyncValidator,
  useAsyncValidatorController
} from '@epi-forms/helpers';
import { isUsernameAvailableRequest } from '@epi-api/username';
import * as Validators from '@epi-helpers/formHelpers/validators';

export function UsernameField({ validators, validateAvailability, label }) {
  const name = FormFields.Username;
  const { control } = useFormContext();
  const { t } = useTranslation();
  const defaultValidators = [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(100),
    Validators.username,
    Validators.noWhitespaces,
    Validators.emailForbidden
  ];
  const rules = createRules(validators || defaultValidators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const usernameAvailabilityValidator = async abortController => {
    const valid = await isUsernameAvailableRequest(value, abortController);

    if (!valid) {
      throw new Error('Username is not available');
    }
  };

  const validateUsername = useAsyncValidator({
    fieldName: name,
    validator: usernameAvailabilityValidator,
    validatorName: FormFields.Username
  });

  const {
    isError,
    isInfo,
    helperText,
    inputProps,
    setAsyncUnverified,
    clearAsyncUnverified
  } = useAsyncValidatorController({
    value,
    error,
    name,
    invalidMsg: t('validation_messages.username_taken'),
    processingMsg: t('validation_messages.username_availability_processing')
  });

  const onChange = event => {
    if (validateAvailability) {
      setAsyncUnverified();
    }
    setValue(event);
  };

  const onBlur = async () => {
    if (validateAvailability) {
      await validateUsername();
      clearAsyncUnverified();
    }
  };

  return (
    <TextField
      label={t(label)}
      required
      value={value}
      id={name}
      error={isError}
      info={isInfo}
      InputProps={validateAvailability ? inputProps : null}
      helperText={helperText || <>&nbsp;</>}
      inputRef={ref}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}

UsernameField.propTypes = {
  validateAvailability: PropTypes.bool,
  validators: PropTypes.array,
  label: PropTypes.string
};

UsernameField.defaultProps = {
  validateAvailability: true,
  validators: null,
  label: 'controls.username_label'
};
