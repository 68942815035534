import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Heading1, ImagePanel, Button, LoadingSpinner } from 'edenred-ui';

import { signUpRequest } from '@epi-api/signUp';
import SignUpImg from '@epi-assets/images/SignUp.png';
import {
  UsernameField,
  PasswordField,
  ConfirmPasswordField
} from '@epi-forms/controls';
import { showNotification } from '@epi-actions/overlays';
import { goToPage } from '@epi-actions/navigation';
import { FormFields } from '@epi-forms/helpers';
import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import { routerPaths } from '@epi-constants/routerPaths';
import { getReCaptchaSiteKey } from '@epi-selectors/settings';
import {
  PasswordValidationContainer,
  PasswordValidation
} from '@epi-shared/components';
import { EmailForbidden } from '@epi-constants/errorName';

function CreateAccountForm({ reCaptchaSiteKey, location, dispatch }) {
  const { t } = useTranslation();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Username]: '',
      [FormFields.Password]: '',
      [FormFields.ConfirmPassword]: ''
    }
  });
  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    watch
  } = form;

  const password = watch(FormFields.Password);
  const username = watch(FormFields.Username);
  const isUsernameSameAsPassword = username !== password;

  const [isProcessingCreateAccount, setIsProcessingCreateAccount] =
    useState(false);

  const isSubmitBtnDisabled =
    [FormFields.Username, FormFields.Password, FormFields.ConfirmPassword].some(
      field => !dirtyFields[field]
    ) ||
    !isEmptyObj(errors) ||
    isProcessingCreateAccount ||
    !isUsernameSameAsPassword;

  const onSubmitForm = async data => {
    setIsProcessingCreateAccount(true);

    const reCaptchaToken = await getRecaptchaToken(reCaptchaSiteKey, 'sign_up');

    const params = new URLSearchParams(location.search);
    const signToken = params.get('token');
    const contactRegistrationType = params.get('type');

    const payload = {
      contactRegistrationType: contactRegistrationType,
      token: signToken,
      userName: data[FormFields.Username],
      password: data[FormFields.Password],
      reCaptchaToken
    };

    try {
      await signUpRequest(payload);
      dispatch(goToPage(`${routerPaths.login}?created-account=true`));
    } catch (e) {
      if (e.content?.errors[0]?.message === EmailForbidden) {
        dispatch(
          showNotification(
            { message: 'validation_messages.email_forbidden', autoDismiss: 0 },
            'error'
          )
        );
      } else {
        dispatch(
          showNotification(
            { message: 'messages.failed_to_create_account', autoDismiss: 0 },
            'error'
          )
        );
      }
    }

    setIsProcessingCreateAccount(false);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        {isProcessingCreateAccount && <LoadingSpinner />}
        <Box pt={15} pb={5} mx="auto" width={1024}>
          <ImagePanel shadow imageSrc={SignUpImg}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
              minHeight={640}
              bgcolor="white"
            >
              <Box width={350}>
                <Box display="flex" justifyContent="center">
                  <Heading1>{t('sign_up.create_account_heading')}</Heading1>
                </Box>
                <Box pt={3}>
                  <UsernameField label={'containers.login.username'} />
                </Box>
                <Box mt={2}>
                  <PasswordField
                    displayValidationResults={false}
                    name="password"
                    label={t('controls.password_label')}
                    showIconPassword
                  />
                </Box>
                <PasswordValidationContainer password={password} />
                <PasswordValidation
                  isValid={isUsernameSameAsPassword}
                  text={t('validation_messages.password_same_as_username')}
                />
                <Box mt={2.5}>
                  <ConfirmPasswordField />
                </Box>
                <Box mt={2.5}>
                  <Button
                    fullWidth
                    id="create-account-btn"
                    disabled={isSubmitBtnDisabled}
                  >
                    {t('sign_up.create_account_btn')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </ImagePanel>
        </Box>
      </form>
    </FormProvider>
  );
}

CreateAccountForm.propTypes = {
  reCaptchaSiteKey: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  reCaptchaSiteKey: getReCaptchaSiteKey(state)
});

const ConnectedCreateAccountForm = connect(mapStateToProps)(
  withRouter(CreateAccountForm)
);

export { ConnectedCreateAccountForm as CreateAccountForm };
